import React from "react"
import { graphql } from 'gatsby'
import LayoutExport from "../components/layouts/layoutExport"
import { Box } from "@chakra-ui/react"
import StaticAdsList from "../components/ad/StaticAdsList"
import Seo from '../components/Seo/';
import AsyncAdsList from "../components/ad/AsyncAdsList"

const siteConfig = require('../../config')

const SellerExport = (props) => {

  const { name, slug } = props.data.seller
  const { ads } = props.data

  const title = {
    "en":
      name + ' used boats for sale'
    ,
    "fr":
      name + ' annonces bateaux occasions à vendre'
  };
  const description = {
    "en":
      name + ': all the ad at the best used price. Photos and descriptions of the boats for sale ads price, purchase, selling.'
    ,
    "fr":
      name + " toutes les annonces au meilleur prix d'occasion. Photos et descriptions du bateaux à vendre annonces prix, achat, vente"
  };
  return (
    <LayoutExport lang={process.env.GATSBY_LANG}>
      <Seo
        title={title[process.env.GATSBY_LANG]}
        description={description[process.env.GATSBY_LANG]}
        slug={slug}
      />
      <Box
        maxW="700px"
        mx="auto"
      >
        {/* <AsyncAdsList
          userID={ 819} 
        /> */}
        <StaticAdsList
          ads={ads}
          baseSlug={slug}
          universID={null}
        />
      </Box>

    </LayoutExport>
  )
}

export default SellerExport

export const pageQuery = graphql`
  query sellerExportQuery($id: String!, $lang: String!){
    ads: allContentfulAd(
      filter:{
        user: { contentful_id: {eq : $id}}
        node_locale: { eq: $lang }
      }
    ){
      edges{
        node{
          id
          contentful_id
          name
          slug
          price
          department
          isBoat
          isPart
          isSail
          isTrolley
          refBrand {
            name
          }
          images {
            url
          }
          date(formatString: "Y")
          publicationDate(formatString: "D/M/Y")
          refUnivers {
            slug
          }
          location {
            lat
            lon
          }
        }
      }
    }
    seller: contentfulSeller(contentful_id: {eq: $id}) {
      slug
      name
      phone
      illu {
        file {
          url
        }
      }
      logo {
        file {
          url
        }
      }
      #hours{
      #  childMarkdownRemark {
      #      html
      #      htmlAst
      #  }
      #}
      #desc{
      #  childMarkdownRemark {
      #      html
      #      htmlAst
      #  }
      #}
      location{
          lat
          lon
      }
      ad{
        id
        name
        slug
        price
        department
        isBoat
        isPart
        isSail
        isTrolley
        refBrand {
          name
        }
        images {
          url
        }
        date(formatString: "Y")
        publicationDate(formatString: "D/M/Y")
        refUnivers {
          slug
        }
        location {
          lat
          lon
        }
      } 
    }
  }
`